import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Slide from "@mui/material/Slide";
import { SlideProps } from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";

import { Background, Parallax } from "react-parallax";

import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import { handlee } from "@/utils/fonts";
import useGallery from "@/hooks/useGallery";
import LoadingIcon from "@/components/LoadingIcon";
import { Image as Images } from "@/interface/image";
import useBreakpoints from "@/hooks/useBreakpoints";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import classes from "../../styles/Gallery.module.css";

type TransitionProps = Omit<SlideProps, "direction">;

const TransitionUp = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
};

const Gallery = ({ imageData }: { imageData: Images[] }) => {
  const {
    adding,
    loading,
    openSnack,
    buttonRef,
    imageInputRef,
    responseMessage,
    uploading,
    setImage,
    handleClose,
    handleCardClick,
    handleFormSubmit,
  } = useGallery();

  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  return (
    <Parallax strength={300}>
      <Background className={"parallaxImage"}>
        <Image
          alt="Gallery parallax Image"
          src={
            smartphone || mobile || tablet
              ? "/anillo_mobile.webp"
              : "/fuerza_desk.webp"
          }
          loading={"lazy"}
          fill
          quality={100}
          style={{
            objectFit: "cover",
          }}
        />
      </Background>

      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"2vh"}
      >
        <Backdrop open={adding || uploading} sx={{ zIndex: 10 }}>
          <LoadingIcon />
        </Backdrop>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          navigation={true}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[EffectCoverflow, Autoplay, Navigation, Pagination]}
        >
          {imageData &&
            imageData.map((item) => {
              return (
                <SwiperSlide key={item.publicId}>
                  <Image
                    src={item.url}
                    alt={item.publicId}
                    fill
                    quality={100}
                    loading="lazy"
                  />
                </SwiperSlide>
              );
            })}

          <SwiperSlide>
            <form onSubmit={handleFormSubmit}>
              <Card
                className={"glassPane"}
                sx={{ height: "400px", borderRadius: "15px" }}
              >
                <CardActionArea onClick={handleCardClick}>
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                      height: "400px",
                    }}
                  >
                    <AddCircleOutlineOutlinedIcon
                      sx={{ color: "var(--color-primary-4)", fontSize: "4rem" }}
                    />
                    <Typography
                      sx={{ color: "var(--color-primary-4)", fontSize: "2rem" }}
                      className={handlee.className}
                    >
                      Sube tu imagen
                    </Typography>
                    <input
                      id="image"
                      name="image"
                      style={{ display: "none" }}
                      ref={imageInputRef}
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      disabled={loading}
                      onChange={(e: any) => {
                        const file = e.target ? e.target.files[0] : null;
                        setImage(file);
                      }}
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
              <button
                id={"submitImage"}
                aria-label={"submit"}
                ref={buttonRef}
                type="submit"
                style={{ display: "none" }}
              ></button>
            </form>
          </SwiperSlide>
        </Swiper>

        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          TransitionComponent={TransitionUp}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {responseMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Parallax>
  );
};

export default Gallery;
