import { gql } from "@apollo/client";

export const ADD_IMAGE = gql`
  mutation AddImage($publicId: String, $width: Int, $height: Int, $createdAt: String, $url: String ) {
    addImage(publicId: $publicId, width: $width, height: $height, createdAt: $createdAt, url: $url) {
      publicId
      width
      height
      createdAt
      url
    }
  }
`
