import { useRouter } from "next/router";
import { WEDDING_FRAME_NAME } from "@/lib/constants";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { ADD_IMAGE } from "@/graphql/mutations/addImage";

const useGallery = () => {

  const [addImage, { loading: adding }] = useMutation(ADD_IMAGE);

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.up("tablet"));
  const mobile = useMediaQuery(theme.breakpoints.between("xs", "tablet"));
  const laptop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const router = useRouter();
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!image) return;
    buttonRef.current?.click();
  }, [image]);

  const handleFormSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formData = new FormData(e.target as HTMLFormElement);

      setUploading(true);
      const response = await fetch('/api/images', {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if(!response.ok) {
        throw data;
      }

      setUploading(false);

      const saveImage = await addImage({
        variables: {
          publicId: data.result.public_id,
          width: data.result.width,
          height: Number(data.result.url.split('h_')[1].split('/')[0]),
          createdAt: data.result.created_at,
          url: data.result.secure_url
        }, onCompleted: (data) => {
          if (data.addImage) {
            setOpenSnack(true);
            setResponseMessage('Listo! Gracias por compartir el momento')
          }
        }
      });

      (saveImage);

    } catch (error) {
      (error);
    } finally {
      setLoading(false);
    }
  }

  const handleCardClick = () => {
    imageInputRef.current?.click();
  }

  const handleClose = () => {
    setOpenSnack(false);
    setResponseMessage('');
    window.location.reload();
  }

  return {
    adding,
    mobile,
    laptop,
    openSnack,
    responseMessage,
    loading,
    buttonRef,
    imageInputRef,
    tablet,
    router,
    uploading,
    setImage,
    handleClose,
    handleCardClick,
    handleFormSubmit,
  }
}

export default useGallery