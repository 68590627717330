import classes from "../styles/LoadingIcon.module.css";
import Stack from "@mui/material/Stack";

const LoadingIcon = () => {
  return (
    <Stack spacing={2}>
      <div className={classes.container}>
        <div className={classes.preloader}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={classes.shadow}></div>
      </div>
    </Stack>
  );
};

export default LoadingIcon;
